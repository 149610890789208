<template>
    <div class="container">
        <div class="orderDetail">
            <p class="p1">请及时付款，以便订单尽快处理！</p>
            <div class="orderDetail-bottom">
                <div class="orderDetail_left">
                    <p>交易号：{{payData.order_list?payData.order_list.sNo:''}}</p>
                </div>
                
                <div class="orderDetail_right">
                    实付：<span>￥{{payData.total}}</span>
                </div>
            </div>
        </div>
        <div class="codeBox">
            <div class="codeBox_left">
                <img :src="code_url" alt="">
                <p v-if="payData.type == 'pc_alipay'">支付宝扫一扫支付</p>
                <p v-else>微信扫一扫支付</p>
            </div>
            <img class="codeBox_right" src="@/assets/image/saoyisao.png" alt="">
        </div>
    </div>
</template>
<script>
import {onMounted,ref,onUnmounted} from 'vue'
import $ from 'jquery'
import {getData,_post1} from '@/utils/req'
import { ElMessage } from 'element-plus'
import {useRouter} from 'vue-router'
export default {
    setup(){
        const router = useRouter()
        let payData = ref('')
        let code_url = ref('')
        let timeI = ref('')
        let LKT_API_URL = ref('https://admin.iseso.net//')
        onMounted(()=>{
            payData.value = JSON.parse(localStorage.getItem('payData'))
            axios()
        })
        onUnmounted(()=>{
            clearInterval(timeI.value)
        })
        const axios =() =>{
            getImg()
            getOrderStatus()
            setTime()
        }
        const getImg = () =>{
            var data = JSON.parse(window.localStorage.getItem('payData'))
            data.order_list = JSON.stringify(data.order_list)
            _post1(data).then(res=>{
                code_url.value = LKT_API_URL.value + res.code_url
            })
        }
        const setTime =() =>{
            timeI.value = setInterval(()=>{
                getOrderStatus()
            },3000)
        }
        const getOrderStatus = () =>{
            var data = {
                module:'app_pc',
                action:'order',
                m:'order_details',
                access_id:payData.value.access_id,
                order_id:payData.value.order_list.order_id // 次数
            }
			getData(data).then(res=>{
                var status = res.data.status
                if(status == 1){
                    ElMessage.success({
                        message:'支付成功',
                        duration:1500
                    })
                    splitOrder(()=>{
                        router.push({
                            path:'/payResult',
                            query:{
                                order_list:JSON.stringify(payData.value.order_list),
                                payStyle:payData.value.type
                            }
                        })
                    })
                }
            })
        }

        const splitOrder = (callback) =>{
            var data = {
                module:'app_pc',
                action:'order',
                m:'leave_Settlement',
                access_id:payData.value.access_id,
                order_list: JSON.stringify(payData.value.order_list),
            }
            $.post('https://admin.iseso.net//index.php?store_id=1&store_type=6', data, () => {
                setTimeout(()=>{
                    callback()
                },100)
            })
        }
        return {
            payData,
            code_url
        }
    }
}
</script>
<style scoped lang="scss">
    .container{
        width: 1200px;
        margin: 0 auto;
        .orderDetail{
            margin-top: 30px;
            padding: 20px;
            background-color: #F6F6F6;
            .p1{
                font-size: 16px;
                line-height: 16px;
                color: #020202;
                font-weight: bold;
                margin-bottom: 20px;
            }
            .orderDetail-bottom{
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .orderDetail_left{
                    font-size: 14px;
                    line-height: 14px;
                    color: #666666;
                }
                .orderDetail_right{
                    span{
                        color: #D4282D;
                        font-weight: bold;
                        font-size: 20px;
                    }
                }
            }
        }
        .codeBox{
            display: flex;
            justify-content: center;
            padding: 70px 0;
            .codeBox_left{
                margin-right: 220px;
                padding-top: 49px;
                p{
                    font-size: 16px;
                    line-height: 16px;
                    color: #020202;
                    font-weight: bold;
                    text-align: center;
                    margin-top: 20px;
                }
                img{
                    width: 130px;
                    height: 130px;
                    padding: 14px;
                    border: 1px solid #EEEEEE;
                }
            }
        }
    }
</style>